// extracted by mini-css-extract-plugin
export var chatgptCasesExpertiseSection = "N_hS";
export var chatgptCasesSection = "N_hW";
export var chatgptDevExpertiseSection = "N_hX";
export var chatgptIndustriesSection = "N_hQ";
export var chatgptPrimeSection = "N_hN";
export var chatgptProcessSection = "N_hT";
export var chatgptProjLogoSection = "N_hZ";
export var chatgptQuotesSection = "N_h0";
export var chatgptServicesSection = "N_hP";
export var chatgptTechStackSection = "N_hV";
export var chatgptTrustedSection = "N_hR";
export var chatgptWhyChooseSection = "N_hY";