// extracted by mini-css-extract-plugin
export var chatGptExpertiseCases__between = "dC_C9";
export var chatGptExpertiseCases__card = "dC_Df";
export var chatGptExpertiseCases__contentAll = "dC_Dl";
export var chatGptExpertiseCases__contentOne = "dC_Dj";
export var chatGptExpertiseCases__contentTwo = "dC_Dk";
export var chatGptExpertiseCases__description = "dC_Dd";
export var chatGptExpertiseCases__imageDesktop = "dC_Db";
export var chatGptExpertiseCases__title = "dC_Dc";
export var chatGptExpertiseCases__wrapperLeft = "dC_Dg";
export var chatGptExpertiseCases__wrapperRight = "dC_Dh";